import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/auth/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/prijava',
        name: 'login',
        component: Login,
        meta: { permissions: [] },
    },
    {
        path: '/odjava',
        name: 'logout',
        meta: { permissions: [] },
        component: () => import('@/views/auth/Logout.vue')
    },
    {
        path: '/ponastavitev-gesla',
        name: 'passwordResetRequest',
        meta: { permissions: [] },
        component: () => import('@/views/auth/PasswordResetRequest.vue')
    },
    {
        //path: '/ponastavitev-gesla/:token',
        path: '/ponastavitev-gesla/:token',
        name: 'passwordReset',
        meta: { permissions: [] },
        component: () => import('@/views/auth/PasswordReset.vue')
    }, 
    /* ERRORS */
    {
        path: '*',
        name: '404',
        meta: { permissions: [] },
        component: () => import('@/views/errors/404.vue')
    },
    {
        path: '/403',
        name: '403',
        meta: { permissions: [] },
        component: () => import('@/views/errors/403.vue')
    },
    {
        path: '/500',
        name: '500',
        meta: { permissions: [] },
        component: () => import('@/views/errors/500.vue')
    },

    /** HOME PAGE */
    {
        path: '/',
        name: 'home_page',
        meta: { permissions: [] },
        component: () => import('@/views/HomePage.vue')
    },

    {
        path: '/registration',
        name: 'registration',
        meta: { permissions: [] },
        component: () => import('@/views/Registration.vue')
    },

    {
        path: '/',
        component: () => import('@/views/RootView.vue'),
        children: [
            {
                path: 'pregled',
                name: 'dashboard' ,
                component: () => import('@/views/Dashboard.vue'),
                //alias: '',
                meta: { requiresAuth: true, permissions: []}
            },

            {
                path: 'koristi-ugodnosti',
                name: 'benefits' ,
                component: () => import('@/views/Benefits.vue'),
                meta: { requiresAuth: true, permissions: []}
            },

            {
                path: 'parking',
                name: 'parking' ,
                //component: () => import('@/views/ParkVehicle.vue'),
                //component: () => import('@/views/ParkVehicle2.vue'),
                component: () => import('@/views/ParkVehicle3.vue'),
                //component: () => import('@/views/ParkVehicle.vue'),
                meta: { requiresAuth: true, permissions: []}
            },
            {
                path: 'user-account',
                name: 'user_account',
                component: () => import('@/views/UserAccount.vue'),
                alias: '',
                meta: { requiresAuth: true, permissions: []}
            },
            {
                path: 'instructions',
                name: 'instructions',
                component: () => import('@/views/Instructions.vue'),
                meta: { requiresAuth: true, permissions: []}
            },
            {
                path: 'terms-and-conditions',
                name: 'terms_and_conditions',
                component: () => import('@/views/TermsAndConditions.vue'),
                meta: { requiresAuth: true, permissions: []}
            },

            /*
            {
                path: 'nastavitve',
                name: 'settings',
                component: () => import('@/views/Settings.vue'),
                meta: { requiresAuth: true, permissions: ['hit-alpinea-view-settings', 'hit-alpinea-update-settings'] }
            },
            */
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    linkActiveClass: 'v-list__tile--active router-link-active active',
    routes
})

export default router