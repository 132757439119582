import CryptoJS from 'crypto-js'

export default {
    SET_USER(state, data) {
    if(data == null)
        {
            state.user.id = null
            state.user.name = null
            state.user.email = null
            state.user.type = null
            state.user.reset = null
            state.user.role = null
            state.user.permissions = null
            state.user.last_seen = null
            state.user.access_token = ""
        }
        else
        {
            state.user.id = data.id
            state.user.name = data.name
            state.user.email = data.email
            state.user.type = data.type
            state.user.role = data.role
            state.user.permissions = data.permissions
            state.user.reset = data.reset
            state.user.last_seen = data.last_seen
            state.user.access_token = data.access_token
        }

        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state.user), process.env.VUE_APP_CRYPTO_JS_KEY).toString();
        //window.console.log("ciphertext: ");
        //window.console.log(ciphertext);

        // Decrypt
        var bytes  = CryptoJS.AES.decrypt(ciphertext, process.env.VUE_APP_CRYPTO_JS_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        //window.console.log("decrypted data:")
        //window.console.log(decryptedData)

        //localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(state.user))
        localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(ciphertext))
    },

    SET_AUTHENTICATED (state, value) {
        state.authenticated = value
    },

    SET_DRAWER (state, value) {
        state.drawer = value
    },

    SET_TOOLBAR_TITLE (state, title) {
        state.toolbarTitle = title
    },

    SET_LOADER (state, value) {
        state.loader = value
    },

    SET_LOADER_TEXT (state, text) {
        state.loaderText = text
    },

    SET_SEARCH (state, search) {
        state.search = search
    },

    SET_SEARCH_FIELD_STATE (state, fieldState) {
        state.searchFieldState = fieldState
    },

    SET_MOBILE_SEARCH (state, mobileSearchState) {
        state.mobileSearch = mobileSearchState
    },

    SET_PROGRESS (state, value) {
        state.progress = value
    },

    SET_CURRENT_ROUTE (state, routeName) {
        state.currentRoute = routeName
    },

    SET_SNACKBAR_TEXT (state, text) {
        state.snackbar.text = text
    },

    SET_SNACKBAR_DISPLAY (state, display) {
        state.snackbar.display = display
    },
    
    SET_SNACKBAR_Y (state, y) {
        state.snackbar.y = y
    },

    SET_SNACKBAR_X (state, x) {
        state.snackbar.x = x
    },

    SET_SNACKBAR_MODE (state, mode) {
        state.snackbar.mode = mode
    },

    SET_SNACKBAR_TIMEOUT (state, timeout) {
        state.snackbar.timeout = timeout
    },

    setSearch (state, search) {
        state.search = search
    },

    setSearchFieldState(state, fieldState) {
        state.searchFieldState = fieldState
    },

    snackbarDisplay (state, display) {
        state.snackbar.display = display
    },

    snackbarY (state, y) {
        state.snackbar.y = y
    },

    snackbarX (state, x) {
        state.snackbar.x = x
    },

    snackbarMode (state, mode) {
        state.snackbar.mode = mode
    },

    snackbarTimeout (state, timeout) {
        state.snackbar.timeout = timeout
    },

    snackbarText (state, text) {
        state.snackbar.text = text
    },

    infoInstructionsDialogState(state, value) {
        state.infoInstructionsDialog = value
    },

    infoTermsAndConditionsDialogState(state, value) {
        state.infoTermsAndConditionsDialog = value
    },

    SET_SITE_LANGUAGE(state, value) {
        state.lang = value
    },

    SET_RESOURCES(state, data) {
        state.resources = data
      },

    SET_PERMIT(state, data) {
        state.permit = data
    },
    
      SET_PERMIT_HOLDERS(state, data) {
        state.permitHolders = data
      },
    
      SET_DEFAULT_APP_SETTINGS(state, obj) {
        state.app_settings = obj
        localStorage.setItem(process.env.VUE_APP_SETTINGS_KEY, JSON.stringify(obj))
      },
    
      SET_STATE_APP_SETTINGS(state, obj) {
    
          let stateSettings = state.app_settings
          let localStorageSettings = obj
    
          for(const ss in stateSettings) {
              if(!(ss in localStorageSettings)) {
                  //window.console.log(ss)
                  localStorageSettings[ss] = stateSettings[ss]
              }
          }
    
    
          state.app_settings = localStorageSettings
    
          //window.console.log(state.app_settings)
          //window.console.log(obj)
      },
    
      APP_SETTING(state, obj) {
        //window.console.log(obj)
        state.app_settings[obj.setting][obj.key] = obj.value
    
        let as = state.app_settings;
        //window.console.log(as)
    
        localStorage.setItem(process.env.VUE_APP_SETTINGS_KEY, JSON.stringify(as))
    
      }
}