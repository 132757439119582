<template>
    <div>
      <v-toolbar
          color="secondary"
          dark
          style="opacity:1;"
      >
        <!--<v-toolbar-title>Dovolilnice | Občina Bled</v-toolbar-title>-->

        <!--<v-divider
          class="mx-4"
          vertical
        ></v-divider>-->
        <v-icon left @click="goToHomePage()">mdi-newspaper-variant-outline</v-icon>
        <span id="toolbar-title" @click="goToHomePage()" class="title-1">{{ translations.app_title }}</span>

        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">

          <v-menu offset-y class="" light bottom left open-on-hover>
              <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" style="margin-right:1px;" @click="setSiteLanguage('sl')" v-if="siteLanguage == 'sl'">
                    <span class="fi fi-si"></span>&nbsp; SL
                  </v-btn>
                  <v-btn text v-on="on" style="margin-right:1px;" @click="setSiteLanguage('en')" v-else>
                    <span class="fi fi-gb"></span>&nbsp; EN
                  </v-btn>
              </template>
              <!--<v-list class="hidden-sm-and-down" color="grey">-->
              <v-list class="">
                <v-list-item @click="setSiteLanguage('en')" v-if="siteLanguage == 'sl'">
                  <v-list-item-title>
                    <span class="fi fi-gb"></span>&nbsp; EN
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="setSiteLanguage('sl')" v-else>
                  <v-list-item-title>
                    <span class="fi fi-si"></span>&nbsp; SL
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>

          <!--<v-btn text @click="changeLanguage()">
            <span class="fi fi-si"></span>&nbsp; SL
            &nbsp;
            <span class="fi fi-gb"></span>&nbsp; EN
          </v-btn>-->

          <v-divider vertical></v-divider>

          <v-btn text @click="instructions()">
            <v-icon left>mdi-information-variant</v-icon>
            <span class="btn_toolbar_link">{{ translations.instructions }}</span>
          </v-btn>

          <v-divider vertical></v-divider>

          <v-btn text @click="termsAndConditions()">
            <v-icon left>mdi-lock</v-icon>
            <span style="text-transform:none;" class="btn_toolbar_link">{{ translations.terms_and_conditions }}</span>
          </v-btn>

          <v-divider vertical></v-divider>

          <v-btn @click="goToHomePage()" text v-if="!isHomePage">
            <v-icon left>mdi-home</v-icon>
            <span class="btn_toolbar_link">{{ translations.home }}</span>
          </v-btn>

          <v-divider vertical v-if="isPasswordResetPage || (isRegistrationPage && !userLoggedIn)"></v-divider>

          <v-btn @click="goToLoginPage()" text v-if="!isLoginPage && !userLoggedIn">
            <v-icon left>mdi-login</v-icon>
            <span class="btn_toolbar_link">{{ translations.login }}</span>
          </v-btn>
          
          <v-divider vertical v-if="!isHomePage"></v-divider>

        <!-- prvotni meni
          <v-menu offset-y class="" light v-if="userLoggedIn" bottom left>
              <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" style="margin-right:1px;">
                      <v-icon left>{{ userIcon }}</v-icon>
                      <span class="btn_toolbar_link">{{ userName }}</span>
                  </v-btn>
              </template>
              <v-list class="hidden-sm-and-down" color="grey">
              <v-list class="">
                <v-list-item @click="goToDashboard()">
                      <v-list-item-action>
                          <v-icon color="grey darken-3">mdi-view-dashboard</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title><span class="">Nadzorna plošča</span></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="logout">
                      <v-list-item-action>
                          <v-icon color="grey darken-3">mdi-logout</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title><span class="">Odjava</span></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
              </v-list>
          </v-menu>
        -->

        <v-menu offset-y class="" light v-if="userLoggedIn" bottom left open-on-hover>
              <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" style="margin-right:1px;" @click="goToDashboard()">
                      <v-icon left>mdi-view-dashboard</v-icon>
                      <span class="btn_toolbar_link">{{ translations.dashboard }}</span>
                  </v-btn>
              </template>
              <v-list class="">
                  <v-list-item @click="logout">
                      <v-list-item-action>
                          <v-icon color="grey darken-3">mdi-logout</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title><span class="">{{ translations.logout }}</span></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
              </v-list>
          </v-menu>

        </v-toolbar-items>

        <!-- mobile language switcher -->
        <v-menu offset-y light bottom left v-if="$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" style="margin-right:1px;" v-if="siteLanguage == 'sl'">
                    <span class="fi fi-si"></span>&nbsp; SL
                  </v-btn>
                  <v-btn text v-on="on" style="margin-right:1px;" v-else>
                    <span class="fi fi-gb"></span>&nbsp; EN
                  </v-btn>
              </template>
              <!--<v-list class="hidden-sm-and-down" color="grey">-->
              <v-list class="">
                <v-list-item @click="setSiteLanguage('en')" v-if="siteLanguage == 'sl'">
                  <v-list-item-title>
                    <span class="fi fi-gb"></span>&nbsp; EN
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="setSiteLanguage('sl')" v-else>
                  <v-list-item-title>
                    <span class="fi fi-si"></span>&nbsp; SL
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>

          <v-divider vertical v-if="$vuetify.breakpoint.smAndDown"></v-divider>
          <span v-if="$vuetify.breakpoint.smAndDown">&nbsp;</span>
          

        <!-- mobile menu -->
        <v-menu offset-y light bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="hidden-md-and-up" icon v-on="on" style="margin-right:1px;">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                      <!--
                        <v-list-item v-if="userLoggedIn" @click="goToSettings()">
                            <v-list-item-icon>
                                <v-icon>{{ userIcon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title><span class="">{{ userName }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        -->

                        <v-list-item v-if="userLoggedIn" @click="goToDashboard()">
                            <v-list-item-icon>
                                <v-icon>mdi-view-dashboard</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title><span class="">{{ translations.dashboard }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                      
                        <v-list-item @click="instructions()">
                            <v-list-item-icon>
                                <v-icon>mdi-information-variant</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title ><span class="">{{ translations.instructions }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="termsAndConditions()">
                            <v-list-item-icon>
                                <v-icon>mdi-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title ><span class="">{{ translations.terms_and_conditions }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="goToHomePage()" text v-if="!isHomePage">
                            <v-list-item-icon>
                                <v-icon>mdi-home</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title ><span class="">{{ translations.home }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="!isLoginPage && !userLoggedIn" @click="goToLoginPage()">
                            <v-list-item-icon>
                                <v-icon>mdi-login</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title text><span class="">{{ translations.login }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider v-if="userLoggedIn"></v-divider>
                        <v-list-item @click="logout" v-if="userLoggedIn">
                            <v-list-item-action>
                                <v-icon color="grey darken-3">mdi-logout</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title><span class="">{{ translations.logout }}</span></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      </v-toolbar>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getUserSettingsFromStorage, getUserApplicationRole } from '@/helpers/utilities'

export default {
    name: 'app-bar-front-page',
    data: () => ({

    }),

    computed: {
        getLogo() {
            return 'https://cloud.conforma.si/public/img/obcina-bled/grb_bled_135_135.png'
        },

        isHomePage() {
          if(this.$route.name === 'home_page') {
            return true
          }

          return false
        },

        isLoginPage() {
          if(this.$route.name === 'login') {
            return true;
          }

          return false 
        },

        isPasswordResetPage() {
          if(this.$route.name == 'passwordResetRequest' || this.$route.name == 'passwordReset') {
            return true
          }

          return false
        },

        isRegistrationPage() {
          if(this.$route.name == 'registration') {
            return true
          }

          return false
        },

        

        userLoggedIn() {
          let userVuexStore = this.$store.getters.user
          let userLocalStorage = getUserSettingsFromStorage(this.$store.getters.app.appKey)

          if(userVuexStore.access_token !== null && userLocalStorage.access_token !== null && userVuexStore.access_token === userLocalStorage.access_token) {
            if(userVuexStore.access_token !== '' && userLocalStorage.access_token !== '') {
              return true
            }
          }

          return false
        },

        userIcon() {
          if(getUserApplicationRole() === 'admin') {
              return 'mdi-incognito'
          }
          
          return 'mdi-account-circle'
        },

        userName() {
          return this.$store.getters.user.name
        },

        siteLanguage() {
          return this.$store.getters.siteLanguage
        },

        translations() {
          let siteLanguage = this.$store.getters.siteLanguage

          return this.$store.getters.translations[siteLanguage]
        }
    },

    methods: {
      goToHomePage() {
        if(this.$route.name !== 'home_page') {
          this.$router.push({
            name: 'home_page'
          })
        }
      },

      goToLoginPage() {
        if(this.$route.name !== 'login') {
          this.$router.push({
            name: 'login'
          })
        }
      },

      goToDashboard() {
          this.$router.push({
              name: 'user_account'
            })
      },

      goToSettings() {
        if(getUserApplicationRole() == 'admin') {
            this.$router.push({
              name: 'admin_settings'
            })
          }
          else {
            this.$router.push({
              name: 'settings'
            })
          }
      },

      termsAndConditions() {
        this.$store.dispatch('SET_INFO_TERMS_AND_CONDITIONS_DIALOG', true);
      },

      instructions() {
        this.$store.dispatch('SET_INFO_INSTRUCTIONS_DIALOG',true)
      },

      logout() {
            this.$store.commit('SET_LOADER_TEXT', 'Odjava uporabnika je v teku...')
            this.$store.commit('SET_LOADER', true)

            this.$store.dispatch('LOGOUT')
            .then(response => {

                setTimeout(() => { 
                    this.$store.commit('SET_LOADER', false) 
                    this.$router.push({
                        name: 'login'
                    })
                }, 800)
            })
            .catch(error =>{
                setTimeout(() => { this.$store.commit('SET_LOADER', false) }, 800),
                window.console.log('Logout error')
                window.console.log(error)

            })
        },

        changeLanguate() {
          window.console.log("changing language....")
        },

        setSiteLanguage(lang) {
          this.$store.commit('SET_SITE_LANGUAGE', lang)

          //window.console.log(lang)

          //window.console.log(this.$store.getters.siteLanguage)

        }
      
    },

    created() {
      //window.console.log("Store User Settings:")
      //window.console.log(this.$store.getters.user)

      //window.console.log("Local Storage User settings:")
      //window.console.log(getUserSettingsFromStorage(this.$store.getters.app.appKey))

      //window.console.log(this.$route.name)
    }
}
</script>

<style scoped>
  #toolbar-title {
    cursor: pointer !important;
  }

  .btn_toolbar_link {
    text-transform: capitalize;
  }
</style>