<template>
    <v-app id="inspire">
        <app-bar-front-page></app-bar-front-page>
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                <v-flex xs12 sm8 md8 lg4>
                    <v-card class="elevation-5 pa-3">
                    <v-card-text>
                        <div class="layout column align-center"><br>
                        <img :src="getLogo" :alt="translations.password_reset_title"  height="auto" :width="($vuetify.breakpoint.xsOnly) ? '95%' : '90%'">
                        <br>
                        <div>
                            <p class="headline font-weight-medium text-center">{{ translations.nc }}</p>
                        </div>
                        <div>
                            <p class="title font-weight-medium text-center">{{ translations.login }}</p>
                        </div>
                        <v-layout align-center justify-center="">
                            <v-flex xs12 sm12 md12 lg12>
                                <div class="">
                                    <p style="text-align:center;" class="font-weight-medium">{{ translations.login_instructions }}</p>
                                </div>
                            </v-flex>
                        </v-layout>
                        </div>                
                        <br>
                        <v-form ref="loginForm" v-model="valid" lazy-validation>
                        <v-text-field
                            append-icon="mdi-email"
                            name="login" 
                            :label="translations.label_email" 
                            type="email" 
                            :rules="[ruleEmailRequired, ruleEmailValidation]" 
                            v-model="credentials.username"
                            :error="error"
                            :error-messages="errors['message']"
                            @keyup.enter="login"
                            color="tertiary"
                            >
                        </v-text-field>
                        <v-text-field id="password" append-icon="mdi-lock" name="password" :label="translations.label_password" type="password" :rules="[rulePasswordRequired, rulePasswordMin]" v-model="credentials.password" @keyup.enter="login" color="tertiary"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" dark color="secondary" @click.native="login" :loading="loginLoading">{{ translations.login }}</v-btn>
                        <v-btn class="white--text" dark color="secondary" @click.native='passwordReset'>{{ translations.forgot_password }}</v-btn>
                        <br><br>
                    </v-card-actions>
                    <v-card-text v-else>
                        <v-btn block outlined  style="margin-bottom:10px;" class="white--text" dark color="secondary" @click.native="login" :loading="loginLoading">{{ translations.login }}</v-btn>
                        <v-btn outlined block class="white--text" dark color="secondary" @click.native='passwordReset'>{{ translations.forgot_password }}</v-btn>
                    </v-card-text>
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>
            <network-status></network-status>
        </v-main>
    </v-app> 
</template>

<script>
import router from '@/router'
import { post } from '@/helpers/api'
import logo from '@/assets/logo.png'
import CardBohinjTitle from '@/assets/digital_card_title.png'
import { getUserApplicationRole, getUserApplicationPermissions, checkPermissions } from '@/helpers/utilities'
import AppBarFrontPage from '@/views/layout/AppBarFrontPage'
const NetworkStatus = () =>import('@/components/NetworkStatus')

export default {
    name: 'login',
    props: {
        source: String
    },

    components: {
        NetworkStatus,
        AppBarFrontPage
    },

    data: () => ({
        title: 'Nadzorni Center - Kartica Julijske Alpe: Bohinj',
        valid: true,
        avatarSize: 32,
        tile: true,
        drawer: null,
        credentials: {
            username: '',
            password: ''
        },

        emailRules: [
            (v) => !!v || "",
            (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Vneseni elektronski naslov ni veljaven.'
        ],
        passwordRules: [
            (v) => !!v || 'Geslo je obvezno.',
            (v) => v.length >= 6 || 'Geslo mora vsebovati vsaj 8 mestni zapis.'
        ],

        loginLoading: false,
        error: false,
        errors: {},
        timeout1: null,
    }),

    computed: {
        getLogo() {
            //return logo
            return CardBohinjTitle
        },

        translations() {
            let siteLanguage = this.$store.getters.siteLanguage

            return this.$store.getters.translations[siteLanguage]
        }
    },

    methods: {
        login() {
            var vm = this
           if(this.$refs.loginForm.validate()) {
            this.loginLoading = true;
            this.error = false;
            this.errors = {}

            this.$store.dispatch('LOGIN_SPA', {email: this.credentials.username, password: this.credentials.password})
              .then(response => {
                //window.console.log(response)
                let userApplicationRole = getUserApplicationRole();
                let userAppPermissions = getUserApplicationPermissions();

                //window.console.log("Application role: " + userApplicationRole);
                //window.console.log("User App Permissions: ")
                //window.console.log(userAppPermissions)

                this.$store.dispatch('PARAMETERS_DATA')
                .then(response => {
                    //window.console.log(response.data)
                    this.$store.commit('SET_RESOURCES', response.data)
                })
                .catch(err => {
                    //window.console.error("### PARAMETERS_DATA ###")
                    window.console.error(err)
                })
                .finally(() => {
                    this.$store.commit('SET_PROGRESS', false)
                    return router.push({name: 'user_account'});   
                })
                

                //window.console.log("redirec to to user_account");
                //router.push({ name: 'user_account'});   
                //return router.push({name: 'user_account'});   

              })
              .catch(err => {
                window.console.error("Error")
                window.console.log(err)
                //window.console.log(error.response)
                //window.console.log(error)
                this.error = true;
                this.loginLoading = false;
                if(err.response.status == 401) {

                let msg = this.translations.message.wrong_username_or_password;

                  this.errors = { message: msg }
                }
                else {
                    let msg = this.translations.message.login_error;
                    
                    this.errors = { message: msg}
                }
              })
              .then(() => {
                this.loginLoading = false;
              })
            } 
        },

        passwordReset() {
            this.$router.push({
                name: 'passwordResetRequest'
            })
        },

        ruleEmailRequired() {
            if(this.credentials.username == null || this.credentials.username == "") {
                return this.translations.rules.email_required
            }  

            return true
        },

        ruleEmailValidation() {
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.credentials.username)) {
                return this.translations.rules.email_validation
            }

            return true
        },

        rulePasswordRequired() {
            if(this.credentials.password == null || this.credentials.password == "") {
                return this.translations.rules.password_required
            }  

            return true
        },

        rulePasswordMin() {
            if(this.credentials.password.length <=6) {
                return this.translations.rules.password_min
            }

            return true
        }
    },

    created() {

    },

    beforeDestroy() {
        clearTimeout(this.timeout1);
    }
}

</script>

<style scoped>
    #inspire{
        background-image: url("../../assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }
</style>