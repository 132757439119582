import axios from 'axios';
import { post, get, destroy, download, API_ENDPOINT, SANCTUM_CSRF_EDNPOINT } from '@/helpers/api'

export default {
    ROUTE_NAME (context, routeName) {
        context.commit('SET_CURRENT_ROUTE', routeName)
    },

    LOGIN_SPA(context, credentials) {
        let responseData = {
          error: null,
          data: null,
          success: false
        };
    
        return new Promise((resolve, reject) => {
          get(SANCTUM_CSRF_EDNPOINT)
          .then(response => {
            //window.console.log("### CSRF TOKEN success ###");
            //window.console.log(response);
            post(API_ENDPOINT + 'auth/login', credentials)
            .then(response => {
              //window.console.log(response.data)
              context.commit('SET_USER', response.data);
              context.commit('SET_AUTHENTICATED', true);
    
              responseData.success = true;
              responseData.data = response.data;
    
              resolve(response);
            })
            .catch(error => {
              window.console.error("### LOGIN error ###");
              window.console.log(error);
    
              responseData.success = false;
              responseData.error = error;
    
              reject(error);
            });
          })
          .catch(error => {
            window.console.log("### LOGIN CSRF TOKEN error ###");
            window.console.error(error);
    
            responseData.success = false;
            responseData.error = error;
    
            reject(error);
          });
        });
      },
    
      LOGOUT (context) {
        return new Promise((resolve, reject) => {
          get(API_ENDPOINT + 'auth/logout')
          .then(response => {
            context.commit('SET_USER', null);
            context.commit('SET_AUTHENTICATED', false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
        });
      },
  
    PASSWORD_RESET_REQUEST (context, email) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/create', email)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/reset', credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_TOKEN_FIND(context, token) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'password/find/' + token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  


    

    PARAMETERS_DATA (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/resources')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    

    CONVERT_RFID_NR (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'utilities/rfid-nr/5', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RFID_NR_SEARCH (context, nr) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'utilities/rfid-nr/5/' + nr)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    

    PARK_VEHICLE(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/events/park-vehicle', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CREATE_PARKING_RECORD(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'demo/card-bohinj/create-parking-record', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_REGISTRATION(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/user-registration', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_ACCOUNT_INFO (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1/services/account/info')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_ACCOUNT_UPDATE(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/account/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    FERATEL_IDENTIFICATION(context, payload) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/identification', payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    REMOVE_CARD (context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1/services/account/remove-card')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    SET_INFO_INSTRUCTIONS_DIALOG(context, value) {
        context.commit('infoInstructionsDialogState', value)
    },

    SET_INFO_TERMS_AND_CONDITIONS_DIALOG(context, value) {
        context.commit('infoTermsAndConditionsDialogState', value)
    }


}