export default {
    user (state) {
        return state.user
    },

    authenticated(state) {
        return state.authenticated
    },

    loader (state) {
        return state.loader
    },

    progress (state) {
        return state.progress
    },

    loaderText (state) {
        return state.loaderText
    },

    drawer (state) {
        return state.drawer
    },

    toolbarTitle (state) {
        return state.toolbarTitle
    },

    snackbar (state) {
        return state.snackbar
    },

    search (state) {
        return state.search
    },

    app (state) {
        return state.app
    },

    access_log (state) {
        return state.access_log
    },

    currentRouteName (state) {
        return state.currentRoute
    },

    searchFieldState (state) {
        return state.searchFieldState
    },

    mobileSearch (state) {
        return state.mobileSearch
    },

    siteLanguage (state)  {
        return state.lang
    },

    translations(state) {
      return state.translations  
    },

    resources(state) {
        return state.resources
    },

    permit(state) {
        return state.permit
    }
}