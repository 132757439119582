<template>
  <v-app>
    <transition name="fade">
      <div>
        <router-view></router-view>
      </div>
    </transition>

    <info-terms-and-conditions></info-terms-and-conditions>
    <info-instructions></info-instructions>

    <cookie-law theme="dark-lime" :buttonText="translations.cookie_confirmation">
      <div slot="message">
        {{  translations.cookie_notification  }}
        <!--<router-link to="legal-notes">Click here</router-link>-->
      </div>
    </cookie-law>

  </v-app>
</template>

<script>
  import { interceptors } from '@/helpers/api'
  import CookieLaw from 'vue-cookie-law'
  const InfoTermsAndConditions = () => import('@/views/InfoTermsAndConditions.vue')
  const InfoInstructions = () => import('@/views/InfoInstructions.vue');

export default {
  components: {
    InfoTermsAndConditions,
    InfoInstructions,
    CookieLaw
  },

  data: () => ({
    interval1: null
  }),

  computed: {
    translations() {
        let siteLanguage = this.$store.getters.siteLanguage

        return this.$store.getters.translations[siteLanguage]
    }
  }, 

  methods: {
    fetchResources() {
      this.$store.commit('SET_PROGRESS', true)
      this.$store.dispatch('PARAMETERS_DATA')
      .then(response => {
        //window.console.log(response.data)
        this.$store.commit('SET_RESOURCES', response.data)
      })
      .catch(err => {
        //window.console.error("### PARAMETERS_DATA ###")
        window.console.error(err)
      })
      .finally(() => {
        this.$store.commit('SET_PROGRESS', false)
      })

    },

    fetchPermitData() {
      this.$store.commit('SET_PROGRESS', true)
      this.$store.dispatch('USER_ACCOUNT_INFO')
      .then(response => {
        //window.console.log(response.data)
        let data = response.data
        
        this.$store.commit('SET_PERMIT', response.data)

      })
      .catch(err => {
        //window.console.error("### USER_ACCOUNT_INFO ###")
        window.console.error(err)
      })
      .finally(() => {
        this.$store.commit('SET_PROGRESS', false)
      })
    }
  },

  created() {
    interceptors((err) => {
      if(err.response.status === 401) {
        //Auth.remove()
        //revoke() clear local storage
        this.$store.commit('SET_USER', null);
        if(this.$route.name != 'login') {
          return this.$router.push({ name: 'login'})
        }
      }

      if(err.response.status === 500) {
        //Flash.setError(err.response.statusText)
        return this.$router.push({name: '500'})
      }

      if(err.response.status === 404) {
      //https://www.bountysource.com/issues/38142121-missing-param-for-named-route-notfound-expected-0-to-be-defined
        return this.$router.push({ name: '404', params: { '0': '404' } }) 
      }

      if(err.response.status === 403) {
      //https://www.bountysource.com/issues/38142121-missing-param-for-named-route-notfound-expected-0-to-be-defined
        return this.$router.push({ name: '403', params: { '0': '403' } }) 
      }

    })

    this.fetchResources()
    //this.fetchPermitData()

    this.interval1 = setInterval(() => {
      let auth = this.$store.getters.authenticated;
      if(auth) {
        let resources = this.$store.getters.resources
        if(resources === null) {
          this.fetchResources()
        }

        let permit = this.$store.getters.permit
        if(permit === null) {
          this.fetchPermitData()
        }
      }
    }, 3000)
  },

  mounted() {
    
  },

  beforeUnmount() {
    clearInterval(this.interval1)
  },
}
</script>

<style>
  html { overflow-y: auto }
  .pointer {cursor: pointer;}
  .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
  }

  .fade-enter-active {
      transition-delay: .25s;
  }

  .fade-enter, .fade-leave-active {
      opacity: 0
  }
</style>
