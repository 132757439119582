const Menu = [
    { header: 'Nadzorna Plošča', header_en: "Control Panel" },
    
    {
        name: 'user_account',
        title: 'Uporabniški račun',
        title_en: 'User account',
        icon: 'mdi-account-circle',
        path: '/user-account',
        group: 'user_account',
        permissions: [],
        top_level: true
    },

    {
        name: 'benefits',
        title: 'Koristi ugodnosti',
        title_en: 'Free services and discounts',
        icon: 'mdi-wallet-giftcard',
        path: '/koristi-ugodnosti',
        group: 'benefits',
        permissions: [],
        top_level: true
    },
    
    
    /*
    {
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/nastavitve',
        group: 'settings'
    },
    */

    {
        name: 'parking',
        title: 'Parkiraj',
        title_en: 'Park car',
        icon: 'mdi-parking',
        path: '/parking',
        group: 'parking',
        permissions: [],
        top_level: true
    },

    {
        name: 'dashboard',
        title: 'Uporaba kartice',
        title_en: 'Use of the card',
        icon: 'mdi-view-dashboard',
        path: '/pregled',
        group: 'dashboard',
        permissions: [],
        top_level: true
    },

    {
        name: 'instructions',
        title: 'Navodila za uporabo',
        title_en: 'Instructions for use',
        icon: 'mdi-information-variant',
        path: '/instructions',
        group: 'instructions',
        permissions: [],
        top_level: true
    },
    {
        name: 'terms_and_conditions',
        title: 'Pogoji uporabe in zasebnost',
        title_en: 'Terms of Use and Privacy',
        icon: 'mdi-note-text-outline',
        path: '/terms-and-conditions',
        group: 'terms_and_conditions',
        permissions: [],
        top_level: true
    }
        
]

export { Menu }